import axios from 'axios'
import { Message } from 'element-ui'  //MessageBox,
import { Loading } from 'element-ui';
import router from '../router/index'

// create an axios instance
const service = axios.create({
  baseURL:'/',
  timeout: 10000 // request timeout
})

let loadingInstance = ''
let requestNum = 0;

// request interceptor
service.interceptors.request.use(
  config => {
    requestNum++;
    loadingInstance = Loading.service({ fullscreen: true });
    // do something before request is sent
 
    return config
  },
  error => {

    // console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(

  response => {
    requestNum--;
    if (requestNum == 0) {
      loadingInstance.close();
    }
    const res = response.data
    if (response.request.responseURL != undefined && response.request.responseURL != '' && response.request.responseURL.indexOf("export") != -1) {
      return response;
    }
    // if the custom code is not 20000, it is judged as an error.
    if (res.code != 200) {
      if (res.code == 500) {
        Message({
          message: '系统错误，请联系管理员',
          type: 'error',
          duration: 5 * 1000
        })
        router.push({ path: '/login' })
      } else if (res.code == 401) {
        Message({
          message: '您已经在其他地方登陆了，请重新登录',
          type: 'error',
          duration: 5 * 1000
        })
        router.push({ path: '/login' })
      } else {
        Message({
          message: res.message || res.mseeage || 'Error',
          type: 'error',
          duration: 5 * 1000
        })
      }

      return Promise.reject(new Error(res.message || res.mseeage || 'Error'))
    } else {
      return res
    }
  },
  error => {
    // console.log('err' + error) // for debug
    requestNum--;
    if (requestNum == 0) {
      loadingInstance.close();
    }
    Message({
      message: '请检查网络',
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
